import React, {useEffect} from 'react';
import WithOwnerUser from "../../helpers/WithOwnerUser";
import styles from "../ModeratorPanel/ModeratorPanel.module.scss";

import OwnerNavbar from "../../components/OwnerNavBar/OwnerNavBar";
import OwnerBody from "../../components/OwnerBody/OwnerBody";
import {useMatch, useNavigate} from "react-router-dom";

const OwnerPage = () => {
    const navigate = useNavigate();
    const owner = useMatch("/owner")

    useEffect(() => {
        if(owner) navigate("/owner/verification")
    }, [])

    return (
        <WithOwnerUser>
            <div className={styles.pageContent}>
                <OwnerBody/>
                <OwnerNavbar/>
            </div>
        </WithOwnerUser>
    );
};

export default OwnerPage;