import React from 'react';
import styles from "../ModeratorBody/ModeratorBodyMenu/ModeratorBodyMenuTable/ModeratorBodyMenuTable.module.scss";
import {t} from "i18next";
import {FcPositiveDynamic} from "react-icons/fc";


const CreateBundleButton = ({handleClick}) => {
    return (
        <div className={styles.createProductBtnBlock}>
            <button
                className={styles.createCategoryBtn}
                onClick={handleClick}>
                <FcPositiveDynamic />
                <span>{t("Create Bundle")}</span>
            </button>

        </div>
    );
};

export default CreateBundleButton;