import React, {useEffect} from 'react';
import styles from './PersonalInfoTab.module.scss'
import PersonalCard from "../PersonalCard/PersonalCard";
import rootStore from "../../../store/RootStore";
import {observer} from "mobx-react";
import {FormControlLabel, Switch} from "@mui/material";

import { useTheme} from "../../../helpers/ThemeContext";
import {Link} from "react-router-dom";
import {t} from "i18next";
const PersonalInfoTab = observer(() => {
    const {getProfile, userData, changePhote, isOwner, isSupport, isSuperAdmin, isCurier} = rootStore.userStore;

    useEffect(() => {
        getProfile()
    }, []);

    const handleChangeAvatar = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData();
        formData.append('photo', file);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };

        await changePhote(formData, config)
    }

    return (
        <div className={styles.profileCard}>
            <div className={styles.container}>
                <div style={{opacity: 1}}>
                    <div className={styles.mainBlock}>
                        <div className={styles.imageContainer}>
                            <div className={styles.imageWrapper}>
                                <div className={styles.profileImage}>
                                    <img
                                        src={`${userData.profile.photo ? userData.profile.photo : "https://consumer-user.wolt.com/s/_wt8B-lIufWCfSysvGahB6Q4vravvbuUB8kWumSIjK0/CH/g"}`}
                                        alt="Profile"/>
                                </div>
                                <div>
                                    <div>
                                        <input type="file" name="file" id="file" className={styles.uploadInput}
                                               accept="image/*" value=""
                                               onChange={(e) => handleChangeAvatar(e)}/>
                                        <label htmlFor="file" className={styles.uploadLabel}>
                                            <span className={styles.description}>Edit</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.userInfo}>
                                <div className={styles.userName}>
                                    <h3 className={styles.userNameText}>{userData.profile.username}</h3>
                                </div>
                                <div className={styles.userDetails}>
                                    <div className={styles.detail}>
                                        <h4 className={styles.upTitle}>Email</h4>
                                        <p className={styles.upDescription}>{userData.profile.username + "@gmail.com"}</p>
                                    </div>
                                    <div className={styles.detail}>
                                        <h4 className={styles.downTitle}>Phone number</h4>
                                        <p className={styles.downDescription}>{userData.profile.phone_number}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.cardContainer}>
                            <PersonalCard tokens={userData.profile.wolt_tokens} name={"Wolt Token"}/>
                            <PersonalCard tokens={userData.profile.balance} name={"Balance"}/>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{display: "flex", flexDirection: "column"}}>
                {isOwner &&
                    <Link className={styles.link} style={{color: 'var(--text-color)'}}
                          to="/owner">
                        <span className={styles.cardText}>{t("Owner page")}</span>
                    </Link>}
                {isSuperAdmin &&
                    <Link className={styles.link} style={{color: 'var(--text-color)'}}
                          to="/moderator">
                        <span className={styles.cardText}>{t("Moderator page")}</span>

                    </Link>}
                {isCurier &&
                    <Link className={styles.link} style={{color: 'var(--text-color)'}}
                          to="/curier-panel/actual">
                        <span className={styles.cardText}>{t("Curier page")}</span>

                    </Link>}
                {/*{isSupport &&*/}
                {/*    <Link className={styles.infoRowLvl} style={{color: 'var(--text-color)'}}*/}
                {/*          to="/support-panel/new">*/}
                {/*        <span className={styles.cardText}>{t("Support")}</span>*/}

                {/*    </Link>}*/}
                {/*{!isSupport && <Link className={styles.infoRowLvl} style={{color: 'var(--text-color)'}}*/}
                {/*                     to="/user-support-panel">*/}
                {/*    <span className={styles.cardText}>{t("Support")}</span>*/}

                {/*</Link>}*/}
            </div>

            <div className={styles.downConteiner}>
                <div className={styles.downBlock}>
                    <div className={styles.downMainBlock}>
                        <div className={styles.boxMain}>
                            <p className={styles.downText}>
                                <span>Your favorites</span>
                            </p>
                            <p className={styles.downTextTwo}>
                                <span>You’ll find your favorite restaurants and stores here. You can add favorites by tapping the heart icon.</span>
                            </p>
                        </div>
                        <div className={styles.iconBlock}>
                            <svg viewBox="0 0 64 80">
                                <path
                                    d="M26.4,35.6c-0.5-0.9-0.7-2-0.4-3.1s1-1.9,1.9-2.4c0.6-0.3,1.3-0.5,2-0.5c1.5,0,2.8,0.8,3.5,2.1l1.5,2.8  c18.2-17-4.7-31.1-8.6-18.6c-4.4-14-32.7,5.4,0,24.9c0.7-0.4,1.4-0.9,2.1-1.3L26.4,35.6z M19.6,16.1c-1.3,0.4-2.9,1.7-3.5,3.7  c-0.6,2.1,0.1,4.6,2,7.3c0.3,0.5,0.2,1.1-0.2,1.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.3,0-0.6-0.1-0.8-0.4c-2.2-3.1-3-6.2-2.3-9  c0.7-2.4,2.6-4.4,4.8-5.1c0.5-0.2,1.1,0.1,1.2,0.7S20.1,16,19.6,16.1z"/>
                                <path
                                    d="M39,51.2c0,0-11.7,0.7-9.3-4.6c0.9-2,4.7-0.7,4.7-0.7l-6.3-11.3c-0.6-1-0.2-2.2,0.8-2.8l0,0c1-0.6,2.2-0.2,2.8,0.8l3.7,6.6  c0,0,1-3.7,3.6-2c0,0,1.5-2.8,4.1-1.1c0,0,1.5-2.8,4.1-1.1c0,0,5.4,7.8,5,13.7l-9.9,5.5L39,51.2z"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="r4tWy6"></div>
            </div>
        </div>
    );
});

export default PersonalInfoTab;