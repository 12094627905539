import api from "../api";

export const getBundlesList = async (id) => {
    try {
        const users = await api.get(`/admin_seller_shop/my-shop/${id}/bundles/`);
        return users.data;
    } catch (error) {
        console.error("An error occurred:", error);
        throw error;
    }
}