import api from "../api";

export const getTextVariants = async (shopId,id) => {
    try {
        const templateVariants=  await api.get(`/admin_seller_shop/my-shop/${shopId}/bundles/${id}/generate-description/`);
        return templateVariants.data;
    } catch (error) {
        console.error("An error occurred:", error);
        throw error;
    }
}