import api from "../api";

export const getLastOrderStatus = async () => {
    try {
        const response = await api.get(`/last-order-status/`);
        return response.data;
    } catch (error) {
        console.error("An error occurred:", error);
        throw error;
    }
}