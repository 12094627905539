import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react";
import ModeratorBodyMenuTable from "./ModeratorBodyMenuTable/ModeratorBodyMenuTable";

import CreateCategoryModal from "./CreateCategoryModal/CreateCategoryModal";
import CreateProductModal from "./CreateProductModal/CreateProductModal";
import EditProductModal from "./CreateProductModal/EditProductModal";
import rootStore from "../../../store/RootStore";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {styled} from "@mui/material";
import {t} from "i18next";
import {a11yProps} from "../../../helpers/a11yProps";
import BundlesTable from "../../BundlesTable/BundlesTable";
import {useLocation} from "react-router-dom";

const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{children: <span className="MuiTabs-indicatorSpan"/>}}
    />
))({
    '& .MuiTabs-flexContainer': {
        justifyContent: 'space-around',
    },
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
        width: '100%',
        backgroundColor: '#635ee7',
    },
});

const ModeratorBodyMenu = observer(() => {
    const {
        getIfOpenCreateCatalog,
        getIfOpenCreateProduct,
        getIsOpenEditProduct,
        getIsOpenSettingShop
    } = rootStore.moderatorPanelStore;
    const [state, setState] = useState(false);

    const location = useLocation();

    useEffect(() => {
        if (location?.state && location?.state?.selectedTab) {
            setValue(location?.state?.selectedTab||0);
        }
    }, [location]);

    function CustomTabPanel(props) {
        const {children, value, index, ...other} = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && <Box>{children}</Box>}
            </div>
        );
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const [value, setValue] = React.useState(0
    );

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <StyledTabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label={t("Products")} {...a11yProps(0)} />
                    <Tab label={t("Bundles")} {...a11yProps(1)} />
                </StyledTabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                {
                    !getIsOpenSettingShop && getIfOpenCreateProduct ?
                        (
                            ""
                        ) :
                        (
                            !getIsOpenSettingShop && !getIsOpenEditProduct ? <>
                                <ModeratorBodyMenuTable/>
                            </> : ""
                        )
                }
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <BundlesTable/>
            </CustomTabPanel>
            {
                !getIsOpenSettingShop && getIfOpenCreateCatalog ?
                    (
                        <CreateCategoryModal setState={setState}/>
                    ) : ""
            }
            {!getIsOpenSettingShop && getIsOpenEditProduct ?
                <EditProductModal setState={setState}/> : ""
            }
            {
                !getIsOpenSettingShop && getIfOpenCreateProduct ?
                    (
                        <CreateProductModal/>
                    ) : ""
            }
        </>
    );
});

export default ModeratorBodyMenu;