import React, {useEffect} from 'react';
import {Outlet, useMatch, useNavigate} from "react-router-dom";
import Header from "../../components/Header/Header"
import rootStore from "../../store/RootStore";
import styles from "./MainPage.module.scss";
import RoundWidget from "../../components/RoundWidget/RoundWidget";

const MainPage = () => {
    const { setWidthSize } = rootStore.systemStore;
    const { checkAuth } =  rootStore.userStore;
    const moderatorPage = useMatch("/moderator")

    useEffect(() => {
        setWidthSize(window.innerWidth);
        checkAuth();
        const handleResize = () => {
            setWidthSize(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        // navigate('/discovery/me')
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className={"app"}>
           <Header />
           <Outlet />
            <RoundWidget/>
            {!moderatorPage && <div className={styles.testFooter}>
                <div className={styles.fakeFooter}>

                </div>
                {/*<div className={styles.footerBlock}>*/}
                {/*    <Footer/>*/}
                {/*</div>*/}
            </div>}
        </div>
    );
};

export default MainPage;