
import api from "../api";

export const addVariantBundleDescription = async (id,shopId,formData) => {
    if(id && shopId && formData) {
        try {
            const apiResponse = await api.post(`/admin_seller_shop/my-shop/${shopId}/bundles/${id}/generate-description/`,formData).then(response => response);
            return apiResponse;
        } catch (error) {
            console.error("An error occurred:", error);
            throw error;
        }
    }
}