import React, {memo} from "react";
import useIsMobile from "../../helpers/useIsMobile";

const SvgComponent = (props) => {
    const isStore = window.location.pathname.includes('/stores/');
    const isMobile = useIsMobile();
    return (
                <div
                style={{
                    paddingTop: "2px",
                    position: "absolute",
                    right: !isMobile && isStore? "259px":!isMobile && !isStore?"10px":"22px",
                    top: "10px",
                    width: '15px',
                    height:'15px',
                    borderRadius: '50%',
                    backgroundColor: "black",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <span style={{
                    textAlign: "center",
                    fontSize: '11px',
                    color: "white",
                }}>
                    {props.content}
                </span>
            </div>
)}
const Badge = memo(SvgComponent)
export default Badge