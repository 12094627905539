import React, {useEffect, useState} from 'react';

import {
    Box,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    styled,
} from "@mui/material";
import {t} from "i18next";

import {Link, useNavigate} from "react-router-dom";
import useIsMobile from "../../helpers/useIsMobile";
import {getBundlesList} from "../../api/getContentsApi/getBundlesList";
import rootStore from "../../store/RootStore";
import {observer} from "mobx-react";
import CreateBundleButton from "../CreateBundleButton/CreateBundleButton";

const BundlesTable = observer(() => {
    const navigate = useNavigate();
    const storedShopValue = localStorage.getItem("currentShop");
    let shopId = rootStore.superUserStore.currentShopId || JSON.parse(storedShopValue)?.ShopID;

    const [bundles, setBundles] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                setLoading(true);
                const data = await getBundlesList(shopId);
                setBundles(data);
            } catch (error) {
                console.error("An error occurred while fetching users:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchUsers();
    }, [shopId]);



    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({

        padding: '7px',
    }));

    const isMobile = useIsMobile()

    const handleClick = () => {
        navigate("/moderator/menu/bundle/")
    }
    return (
        <Paper sx={{width: '100%'}}>
            <CreateBundleButton  handleClick={handleClick} />
            { loading? <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "50vh"}}>
                    <CircularProgress/>
                </Box>:
                <>
                    <TableContainer sx={{maxHeight: "60vh"}}>
                        <Table sx={isMobile ? {maxWidth: 370} : ""} aria-label="users table" >
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell >{t("Bundles name")}</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {bundles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((bundle) => (
                                    <TableRow key={bundle.id}>
                                        <StyledTableCell sx={{maxWidth: 150, wordWrap: 'break-word'}}>
                                            <Link to={`/moderator/menu/bundle/${bundle.id}`}>
                                                {bundle?.name}
                                            </Link>
                                        </StyledTableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        rowsPerPageOptions={[5,10, 25, 50, 100]}
                        component="div"
                        count={bundles.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>}
        </Paper>
    );
});

export default BundlesTable;
