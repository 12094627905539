import React, {useState, useRef, useEffect} from 'react';
import styles from './ModalLocation.module.scss';
import {observer} from "mobx-react";
import rootStore from "../../store/RootStore";
import {useNavigate} from "react-router-dom";
import {t} from "i18next";

const ModalLocation = ({children}) => {
    const {adrressesList, loadAdressesList, setUserAddresMain} = rootStore.addressStore;
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [arrayCitys, setArrayCitys] = useState([]);
    const {getProfile, userData, countrysList} = rootStore.userStore;
    const [selectedCity, setSelectedCity] = useState(() => userData.profile.country || "");
    const [cityPage, setCityPage] = useState(true);
    const modalRef = useRef(null);

    useEffect(() => {
        getProfile()
        loadAdressesList()
    }, []);

    useEffect(() => {
        const home = userData.profile.country || "";
        setSelectedCity(home)
    }, [setSelectedCity, setCityPage, setIsOpen]);

    useEffect(() => {
        if (countrysList.length) {
            countrysList.forEach((item) => {
                setArrayCitys(prevstate => [...prevstate, item.name])
            })
        }
    }, [countrysList]);

    const handleCloseModal = () => {
        setIsOpen(false);
    };

    const handleClose = (ev) => {
        if (ev.target.id === "modalka") {
            setIsOpen(false);
        }
    }
    const isAccPage = window.location.pathname.includes('account-mobile')

    const handleSetUserAddresMain = (address) => {
        setUserAddresMain(address.id)
        setTimeout(() => {
            getProfile()
        }, 100)
    }

    const countryMain = adrressesList.filter(item => item.id === userData.profile.country)[0]

    return (
        <div className={styles.mainContBlock}>
            {isOpen && (
                <div id="modalka" className={`${styles["modal-overlay"]}`} onClick={(ev) => handleClose(ev)}>
                    <div className={`${styles["modal-content"]} ${isAccPage && styles.bigModal}`} ref={modalRef}>
                        <div className={styles.rowBlockCont}>
                            <div className={styles.closeBtnConteiner}>
                                <button className={styles.buttonBlock} data-backdrop="transparent"
                                        data-size="medium" data-variant="neutral" type="button"
                                        aria-label="Close the dialog" data-test-id="modal-close-button"
                                        onClick={handleCloseModal}>
                                    <div className={styles.fakeBtnBlock}></div>
                                    <div className={styles.closeImgBtn}>
                                        <svg viewBox="0 0 24 24" className={styles.closeImg}>
                                            <path
                                                d="M2.44 19.44a1.5 1.5 0 002.121 2.121l7.262-7.26a.25.25 0 01.354 0l7.262 7.262a1.5 1.5 0 102.122-2.12l-7.26-7.266a.25.25 0 010-.354l7.262-7.262a1.5 1.5 0 00-2.124-2.12L12.177 9.7a.25.25 0 01-.354 0L4.561 2.44A1.5 1.5 0 002.44 4.56L9.7 11.823a.25.25 0 010 .354L2.44 19.44z"></path>
                                        </svg>
                                    </div>
                                </button>
                            </div>
                        </div>
                        <div className={styles.conteinerAddress}>
                            {
                                adrressesList.map(address => (
                                    <div className={styles.imagesBlock}
                                         onClick={() => handleSetUserAddresMain(address)}>
                                        <div className={styles.rowBlock}>
                                            <div className={styles.leftBlock}>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"
                                                     className={userData?.profile?.country === address.id ? styles.svgImage__active : styles.svgImage}
                                                     fill="currentColor">
                                                    <path fill-rule="evenodd"
                                                          d="M12.166 8.94C12.696 7.867 13 6.862 13 6A5 5 0 0 0 3 6c0 .862.305 1.867.834 2.94.524 1.062 1.234 2.12 1.96 3.07A31.481 31.481 0 0 0 8 14.58l.208-.22a31.493 31.493 0 0 0 1.998-2.35c.726-.95 1.436-2.008 1.96-3.07zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
                                                    <path fill-rule="evenodd"
                                                          d="M8 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                                </svg>
                                                <div className={styles.textBlockImage}>
                                                    <span
                                                        className={userData?.profile?.country === address.id ? styles.topText__active : styles.topText}>{address.label}</span>
                                                    <span className={styles.downText}>{address.city}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <button className={`${styles.addNewAddress}`}
                                onClick={() => navigate("/discovery/me/my-addresses")}>{t("Add new address")}</button>
                    </div>
                </div>
            )}
            <div onClick={() => setIsOpen(true)}> {children}</div>
        </div>
    );
};

export default observer(ModalLocation);
