import React, {useState, useRef, useEffect} from 'react';
import styles from './LocationAdress.module.scss';
import {observer} from "mobx-react";
import rootStore from "../../../store/RootStore";
import {useNavigate} from "react-router-dom";
import IconDown from "../../../components/Icons/IconDown";
import {t} from "i18next";

const LocationAdress = ({isOpen, setIsOpen}) => {
    const modalRef = useRef(null);
    const navigate = useNavigate();
    const { adrressesList, setUserAddresMain, loadAdressesList } = rootStore.addressStore;
    const { getProfile, userData } = rootStore.userStore;

    useEffect(() => {
        loadAdressesList()
    }, []);


    const handleSetUserAddresMain = (address) => {
        setUserAddresMain(address.id)
        setTimeout(() => {
            getProfile()
        }, 100)
    }

    const handleGoToNewAddress = () => {
        navigate("/discovery/me/my-addresses")
    }

    const [isClosing, setIsClosing] = useState(false);
    const handleClose = (e) => {
        e.stopPropagation()
        setIsClosing(true);
        setTimeout(() => {
            setIsClosing(false);
            setIsOpen(false);
        }, 500);
    }

    return (
        <div>
            {isOpen && (
                <div id="modalka" className={styles["modal-overlay"]}>
                    <div className={`${styles["modal-content"]} ${isClosing ? styles.fadeOutAnimation : ""}`}
                         ref={modalRef}>
                        <div className={styles.closeBtnConteiner}>
                            <button style={{
                                marginTop: "5px",
                                zIndex: 999999,
                            }} onClick={(ev) => handleClose(ev)}>
                                <IconDown></IconDown>
                            </button>
                        </div>
                        <div>
                            <h2 className={styles.titleBody}>Location</h2>
                            <div className={styles.conteinerAdresses}>
                                {
                                    adrressesList.map(address => (
                                        <div className={styles.imagesBlock}
                                             onClick={() => handleSetUserAddresMain(address)}>
                                            <div className={styles.rowBlock}>
                                                <div className={styles.leftBlock}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"
                                                         className={userData?.profile?.country === address.id ? styles.svgImage__active : styles.svgImage} fill="currentColor">
                                                        <path fill-rule="evenodd"
                                                              d="M12.166 8.94C12.696 7.867 13 6.862 13 6A5 5 0 0 0 3 6c0 .862.305 1.867.834 2.94.524 1.062 1.234 2.12 1.96 3.07A31.481 31.481 0 0 0 8 14.58l.208-.22a31.493 31.493 0 0 0 1.998-2.35c.726-.95 1.436-2.008 1.96-3.07zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
                                                        <path fill-rule="evenodd"
                                                              d="M8 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                                    </svg>
                                                    <div className={styles.textBlockImage}>
                                                        <span
                                                            className={userData?.profile?.country === address.id ? styles.topText__active : styles.topText}>{address.label}</span>
                                                        <span className={styles.downText}>{address.city}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className={styles.addAdressBlock} onClick={() => handleGoToNewAddress()}>
                            <span className={styles.plus}>+</span><span
                            className={styles.plusText}>{t("Add new address")}</span>
                        </div>
                    </div>
                </div>
            )}
            <div onClick={() => setIsOpen(true)}></div>
        </div>
    );
};

export default observer(LocationAdress);
