import React, { createContext, useState, useContext, useEffect } from 'react';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
    const [themeMode, setThemeMode] = useState(() => {
        const savedTheme = localStorage.getItem('theme');
        return savedTheme ? savedTheme : 'light';
    });

    const toggleTheme = () => {
        setThemeMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
    };
    const setLightTheme = () => {
        setThemeMode('light');
    }

    useEffect(() => {
        localStorage.setItem('theme', themeMode);

        const root = document.documentElement;
        if (themeMode === 'dark') {
            root.style.setProperty('--background-color', '#000000');
            root.style.setProperty('--text-color', '#fff');
            root.style.setProperty('--other-background-color', '#171717');
            root.style.setProperty('--grey-text-color', '#fff');
            root.style.setProperty('--blue-color', '#fff');
            root.style.setProperty('--light-grey-color', '#fff');
            root.style.setProperty('--light-blue-color', 'rgba(0, 157, 224, 0.1984313725)');
            root.style.setProperty('--green-blue-color', '#2fae23');
            root.style.setProperty('--dark-grey-color', '#383838');
            root.style.setProperty('--dark-bold-grey-color', '#1c1c1c');
            root.style.setProperty('--blue-btn-background-color', '#011925');
            root.style.setProperty('--blue-btn-text-color', '#2990c3');
            root.style.setProperty('--error-color', '#2fae23');
            root.style.setProperty('--order-text-color', '#fff');
            root.style.setProperty('--order-grey-text-color', '#fff');
            root.style.setProperty('--grey-border-color', 'rgba(63, 63, 63, 0.8)');
            root.style.setProperty('--grey-delivery-background-color', 'rgba(2, 172, 248, 0.1)');
            root.style.setProperty('--chat-message-background', '#000000');
            root.style.setProperty('--order-agine-btn-background', '#fff');
            root.style.setProperty('--address-border', '#fff');
            root.style.setProperty('--light-background-color', '#ffffff29');
        } else {
            root.style.setProperty('--background-color', '#fff');
            root.style.setProperty('--text-color', '#000');
            root.style.setProperty('--other-background-color', '#fff');
            root.style.setProperty('--grey-text-color', '#202125a3');
            root.style.setProperty('--blue-color', '#009de0');
            root.style.setProperty('--light-grey-color', '#2021251f');
            root.style.setProperty('--light-blue-color', '#fff');
            root.style.setProperty('--green-blue-color', '#009de0');
            root.style.setProperty('--dark-grey-color', 'rgba(32, 33, 37, .08)');
            root.style.setProperty('--dark-bold-grey-color', '#fff');
            root.style.setProperty('--blue-btn-background-color', '#009de0');
            root.style.setProperty('--blue-btn-text-color', '#fff');
            root.style.setProperty('--error-color', 'red');
            root.style.setProperty('--order-text-color', '#191919');
            root.style.setProperty('--order-grey-text-color', '#646464');
            root.style.setProperty('--grey-border-color', 'rgba(63, 63, 63, 0.1)');
            root.style.setProperty('--grey-delivery-background-color', '#f6f6f6');
            root.style.setProperty('--chat-message-background', '#e2f2f7');
            root.style.setProperty('--order-agine-btn-background', 'rgba(169, 169, 169, 0.3)');
            root.style.setProperty('--address-border', '#f2f2f2');
            root.style.setProperty('--light-background-color', '#ffffff29');
        }
    }, [themeMode]);


    const theme = createTheme({
        palette: {
            mode: themeMode,
        },
    });

    return (
        <ThemeContext.Provider value={{ themeMode, toggleTheme, setLightTheme }}>
            <MuiThemeProvider theme={theme}>
                <CssBaseline />
                {children}
            </MuiThemeProvider>
        </ThemeContext.Provider>
    );
};

export const useTheme = () => useContext(ThemeContext);
