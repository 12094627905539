import React, {useEffect, useState} from 'react';
import {Link, Outlet, useLocation} from "react-router-dom";
import style from './CurierWrapper.module.scss'
import { observer, inject } from 'mobx-react';
import WithAuthUser from "../../helpers/WithAuthUser";
import {useTheme} from "../../helpers/ThemeContext";
import useFooterMargin from "../DiscoveryWrapper/useFootrerMargin";
import BackIcon from "./static/BackIcon";
import ActualOrders from "./static/ActualOrders";
import WorkOrders from "./static/WorkOrders";
import HistoryOrders from "./static/HistoryOrders";
import {t} from "i18next";
import WithCourierUser from "../../helpers/WithCourierUser";


const CurierWrapper = inject('rootStore')(observer(() => {
    const { themeMode, setLightTheme } = useTheme();

    useEffect(() => {
        setLightTheme()
    }, [])
    const {pathname} = useLocation();
    const [hidden, setHidden] = useState(false);

    const [activeLink, setActiveLink] = useState(() => {
        if (pathname.includes( '/restaurants')) {
            return 'link2';
        } else if (pathname.includes('/stores')) {
            return 'link3';
        } else {
            return 'link1';
        }
    });
    const handleButtonClick = (link) => {
        setActiveLink(link);
    };
    useEffect(() => {
        if(pathname.includes("curier-panel/actual")){
            setActiveLink('link1')
        } else if(pathname.includes("curier-panel/worked")) {
            setActiveLink('link2')
        } else if(pathname.includes("curier-panel/all")){
            setActiveLink('link3')
        }
    }, [window.location.pathname]);

    useEffect(() => {
        if(pathname.includes("actual/")){
            setHidden(true)
        } else if(pathname.includes("worked/")) {
            setHidden(true)
        } else if(pathname.includes("all/")){
            setHidden(true)
        }
        else {
            setHidden(false)
        }
    }, [window.location.pathname]);
    useFooterMargin();

    return (
        <WithCourierUser>
                <div className={style['nav-bar']} style={{display: hidden ? 'none' : 'flex'}}>
                    <Link
                        onClick={() => handleButtonClick('link1')}
                        className={`${themeMode === 'dark' ? style.dark : ''} ${style.link} ${activeLink === 'link1' ? style.active : ''}`}
                        to={"actual"}>
                        <ActualOrders/>
                       <span>
                           {t("Actual orders")}
                       </span>
                    </Link>
                    <Link
                        onClick={() => handleButtonClick('link2')}
                        className={`${themeMode === 'dark' ? style.dark : ''} ${style.link} ${activeLink === 'link2' ? style.active : ''}`}
                        to={"worked"}>
                        <WorkOrders/>
                        {t("Worked orders")}
                    </Link>
                    <Link
                        onClick={() => handleButtonClick('link3')}
                        className={`${themeMode === 'dark' ? style.dark : ''} ${style.link} ${activeLink === 'link3' ? style.active : ''}`}
                        to={"all"}>
                        <HistoryOrders currentColor={"currentColor"}/>
                        {t("History orders")}
                    </Link>
                    <Link
                        onClick={() => handleButtonClick('link4')}
                        className={`${themeMode === 'dark' ? style.dark : ''} ${style.link} ${activeLink === 'link4' ? style.active : ''}`}
                        to={"/discovery/me"}>
                        <BackIcon/>
                        {t("Back")}
                    </Link>
                </div>
                <Outlet/>
        </WithCourierUser>
    );
}));

export default observer(CurierWrapper);