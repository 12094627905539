import React, { useEffect, useState } from 'react';
import { t } from "i18next";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./BundleDetails.module.scss";
import IconBack from "../../components/Icons/IconBack";
import { deleteOwnerCategory } from "../../api/addContent/deleteOwnerCategory";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControl, InputLabel, MenuItem,
    Slide,
    styled,
    Select,
    Box,
    CircularProgress,
    TextField
} from "@mui/material";
import useIsMobile from "../../helpers/useIsMobile";
import { getBundlesInfo } from "../../api/getContentsApi/getBundlesInfo";
import rootStore from "../../store/RootStore";
import { getShopProducts } from "../../api/getContentsApi/getShopProduct";
import { addBundle } from "../../api/addContent/addBundle";
import { deleteBundle } from "../../api/addContent/deleteBundle";
import { getTextVariants } from "../../api/getContentsApi/getTextVariants";
import {addVariantBundleDescription} from "../../api/addContent/addVariantBundleDescription"; // Assume this is the API function to get text variants

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const StyledDialog = styled(Dialog)`
    .MuiPaper-root {
        border-radius: 20px !important;
    }
`;

const BundleDetails = () => {
    const storedShopValue = localStorage.getItem("currentShop");
    let shopId = rootStore.superUserStore.currentShopId || JSON.parse(storedShopValue)?.ShopID;
    const navigate = useNavigate();
    const { id } = useParams();

    const [products, setProducts] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [openDelete, setOpenDelete] = useState(false);
    const [openSaveConfirm, setOpenSaveConfirm] = useState(false);
    const [formValues, setFormValues] = useState(null);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [inputFields, setInputFields] = useState([{ count: "", price: "" }]);
    const [sales, setSales] = useState([]);
    const [bundleName, setBundleName] = useState("");
    const [openTextDialog, setOpenTextDialog] = useState(false);
    const [textVariants, setTextVariants] = useState([]);
    const [selectedVariant, setSelectedVariant] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            if (id && shopId) {
                const data = await getBundlesInfo(shopId, id);
                if (data?.salles?.length) {
                    setInputFields(prev =>[ ...data.salles,...prev])
                }
                if(data?.name){
                    setBundleName(data?.name);
                }
                if(data?.items?.length){
                    const items = data?.items;
                    const arr = items.map((item) => item.product)
                    setSelectedProducts(items.length)
                    setSelectedItems(arr);
                }
            }
        };
        fetchData();
    }, [id, shopId]);

    useEffect(() => {
        const fetchData = async () => {
            if (shopId) {
                const data = await getShopProducts(shopId);
                if (!data?.data.products?.length) {
                    handleBack();
                } else {
                    setProducts(data?.data.products);
                }
            }
        }
        fetchData();
    }, [shopId, id]);

    const handleBack = () => {
        navigate("/moderator/menu", { replace: true, state: { selectedTab: 1 } });
    };

    const handleDelete = async () => {
        if (id ) {
            await deleteBundle(id,shopId);
            handleBack()
            setOpenDelete(false);
        }
    }

    const handleCloseDelete = () => {
        setOpenDelete(false);
    }

    const handleCloseSaveConfirm = () => {
        setOpenSaveConfirm(false);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const formattedItems = selectedItems.map(item => ({
            product: item,
            product_id: products.find(product => product.ProductName === item).ProductID
        }));
        setFormValues({ items: formattedItems, name: bundleName });
        if(bundleName && selectedItems.length && inputFields.length && inputFields.filter((item) => item.price).length) {
            setOpenSaveConfirm(true);
        }
    };

    const handleConfirmSubmit = async () => {
        setOpenSaveConfirm(false);
        if (formValues) {
            const data = {
                items: formValues.items,
                name: formValues.name,
                salles: inputFields.length ? inputFields.filter((item) => item.price) : []
            };

            await addBundle(data, id, shopId);
            handleBack();
        }
    };

    const handleGenerateText = async () => {
        setOpenTextDialog(true);
        const data = await getTextVariants(shopId, id); // Assume this function fetches text variants
        setTextVariants(data?.template_variants);
    };

    const handleCloseTextDialog = () => {
        setOpenTextDialog(false);
    };

    const handleApplyVariant =  async() => {
        if (selectedVariant) {
            const data = {
                template_id: selectedVariant.id
            };
            await addVariantBundleDescription(id,shopId, data);
            handleCloseTextDialog();
            handleBack()
        }
    };

    const isMobile = useIsMobile();
    if (!products?.length) {
        return (<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
            <CircularProgress />
        </Box>)
    }

    const handleSelect = async (e) => {
        const value = e.target.value;
        setSelectedItems(value);
        const selectedProducts = value.map(productName => {
            const product = products.find(product => product.ProductName === productName);
            return {
                product: product.ProductName,
                product_id: product.ProductID
            };
        });
        setSelectedProducts(selectedProducts)
    }

    const handleRemoveField = (index,e) => {
        e.preventDefault();
        const updatedFields = inputFields.filter((_, i) => i !== index);
        setInputFields(updatedFields);

        const updatedSales = updatedFields
            .filter(field => parseInt(field.count, 10) > 0 && parseFloat(field.price) > 0)
            .map(field => ({
                count: parseInt(field.count, 10),
                price: parseFloat(parseFloat(field.price).toFixed(2)), // Два знака для price
            }));
        setSales(updatedSales);
    };

    const handleInputChange = (index, field, value) => {
        if (field === "count") {
            // Проверяем, чтобы count был целым числом
            if (/^\d*$/.test(value)) {
                const updatedFields = [...inputFields];
                updatedFields[index][field] = value;
                setInputFields(updatedFields);
            }
        } else if (field === "price") {
            // Проверяем, чтобы price был числом с максимум двумя знаками после запятой
            if (/^\d*\.?\d{0,2}$/.test(value)) {
                const updatedFields = [...inputFields];
                updatedFields[index][field] = value;
                setInputFields(updatedFields);
            }
        }
    };

    const handleAddField = (event) => {
        event.preventDefault();

        const lastField = inputFields[inputFields.length - 1];
        const isUniqueCount = !sales.some(
            field => parseInt(field.count, 10) === parseInt(lastField.count, 10)
        );

        if (
            parseInt(lastField.count, 10) > 0 &&
            parseFloat(lastField.price) > 0 &&
            isUniqueCount
        ) {
            setInputFields([...inputFields, { count: "", price: "" }]);

            const formattedSales = inputFields.map(field => ({
                count: parseInt(field.count, 10),
                price: parseFloat(parseFloat(field.price).toFixed(2))
            }));
            const discount = formattedSales.sort((a, b) => Number(a.count) - Number(b.count));
            setSales(discount);
        }
    };

    const handleBundleNameChange = (event) => {
        setBundleName(event.target.value);
    };

    return (
        <div style={isMobile ? { padding: "10px" } : { padding: "10px", width: "78%" }}>
            <div onClick={handleBack}>
                <IconBack size={20} pure={true} disabled fill={"#009de0"} marg={0} />
                <h3 style={{ textAlign: "center", position: "relative", top: "-30px" }}>{t("Bundle")}</h3>
            </div>
            <div className={styles.mainBlock}>
                <h4>{t('id')}: { id || t('New')}</h4>
                <form onSubmit={handleSubmit} className={styles.form}>
                    <div style={{position: "relative"}}>
                        <FormControl fullWidth>
                            <InputLabel id="categoryName-label">{t("Products")}</InputLabel>
                            <Select
                                variant="outlined"
                                labelId="categoryName-label"
                                id="items"
                                name="categoryName"
                                multiple
                                value={selectedItems}
                                onChange={(e) => handleSelect(e)}
                                label={t("Category name")}
                            >
                                {products.map((product) => (
                                    <MenuItem key={product.ProductID} value={product.ProductName}>
                                        {product.ProductName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            label={t("Bundle Name")}
                            variant="outlined"
                            fullWidth
                            value={bundleName}
                            onChange={handleBundleNameChange}
                            style={{marginTop: "20px"}}
                        />
                        <div className={styles.discountBlock}>
                            <h5 style={{fontWeight: 400}}>{t("Discount")}</h5>
                            {inputFields.map((field, index) => (
                                <div
                                    key={index}
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        gap: "10px",
                                        marginBottom: "5px",
                                    }}
                                >
                                    <div data-label="sales" className={`${styles.input10} ${styles.input}`}>
                                        <label className={styles.main4}>{t("Count")}</label>
                                        <div className={styles.main5}>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                disabled={index !== inputFields.length - 1}
                                                className={styles.main6}
                                                placeholder={t("Enter count")}
                                                value={field.count}
                                                onChange={(e) => handleInputChange(index, 'count', e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div data-label="sales" className={`${styles.input10} ${styles.input}`}>
                                        <label className={styles.main4}>{t("price")}</label>
                                        <div className={styles.main5}>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                disabled={index !== inputFields.length - 1}
                                                className={styles.main6}
                                                placeholder={t("Enter price")}
                                                value={field.price}
                                                onChange={(e) => handleInputChange(index, 'price', e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        {index === inputFields.length - 1 ? (
                                            <div className={styles.addButton} onClick={handleAddField}>+</div>
                                        ) : (
                                            <button style={{padding: "0 1.5rem"}} className={styles.removeButton}
                                                    onClick={(e) => handleRemoveField(index, e)}>-</button>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className={styles["button-container"]}>
                        <button type="submit" className={styles["add-button"]}>
                            {id ? t("Edit bundle") : t("Save bundle")}
                        </button>
                        {id && <button style={{width: "100%", height: "40px", padding: "0 1rem"}} type="button"
                                       onClick={() => setOpenDelete(true)}
                                       className={styles["removeButton"]}>
                            {t("Delete")}
                        </button>}
                    </div>
                    {id &&  <div className={styles["button-container"]}>
                        <button style={{marginTop: '2rem', width: "100%", height: "40px", padding: "0 1rem"}}
                                       type="button"
                                       className={styles["add-button"]} onClick={handleGenerateText}>
                            {t("Generate Description")}
                        </button>
                    </div>}
                </form>
            </div>

            <StyledDialog
                open={openDelete}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseDelete}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{t("Warning")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {t("Are you sure you want to delete bundle?")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDelete}>{t("Cancel")}</Button>
                    <Button onClick={handleDelete}>{t("Delete")}</Button>
                </DialogActions>
            </StyledDialog>

            <StyledDialog
                open={openSaveConfirm}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseSaveConfirm}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{t("Confirmation")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {id ? t("Do you want to edit the bundle?") : t("Do you want to create the new bundle?")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSaveConfirm}>{t("Cancel")}</Button>
                    <Button onClick={handleConfirmSubmit}>{t("Confirm")}</Button>
                </DialogActions>
            </StyledDialog>

            <StyledDialog
                open={openTextDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseTextDialog}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{t("Generated Description")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <FormControl fullWidth>
                            {!selectedVariant && <InputLabel id="text-variant-label">{t("Select Description Variant")}</InputLabel>}
                            <Select
                                labelId="text-variant-label"
                                id="text-variant"
                                value={selectedVariant ? selectedVariant.id : ''}
                                onChange={(e) => setSelectedVariant(textVariants.find(variant => variant.id === e.target.value))}
                            >
                                {textVariants.map((variant,index) => (
                                    <MenuItem
                                        key={variant.id}
                                        value={variant.id}
                                        sx={{
                                            whiteSpace: 'normal',
                                            wordWrap: 'break-word',
                                            width: '100%'
                                        }}
                                    >
                                      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center',gap:'5px'}}>
                                          <p style={{fontWeight:"bold", alignSelf:'baseline'}}>{index + 1 }:</p><p style={{fontWeight:"400"}}>{variant.text}</p>
                                      </div>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseTextDialog}>{t("Close")}</Button>
                    {selectedVariant && <Button onClick={handleApplyVariant}>{t("Apply")}</Button>}
                </DialogActions>
            </StyledDialog>
        </div>
    );
};

export default BundleDetails;
