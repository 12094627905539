import React, { useState, useEffect } from 'react';
import { observer } from "mobx-react";
import styles from './OrderItem.module.scss';
import rootStore from "../../../store/RootStore";
import { useTheme } from "../../../helpers/ThemeContext";
import { getCurrencySymbol } from "../../../helpers/getCurrency";
import { t } from "i18next";
import { Modal, Box, Typography, Button } from "@mui/material";

const OrderItem = observer(({ product, setListItem, listItem, isAccess = true }) => {
    const [isOpen, setOpen] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [name, setName] = useState("");
    const { themeMode } = useTheme();
    const { addProductToCartOrder, minProductToCartOrder, delProductToCartOrder } = rootStore.cartOrderStore;
    const symbol = getCurrencySymbol();

    const handleAddProductToCart = async () => {
        const response = await addProductToCartOrder(product.product.ProductID, 1);
        if (response?.data?.limit_reached) {
            setName(response?.data?.product_name);
            setShowNotification(true);
        }
    };

    const handleMinProductToCart = async () => {
        await minProductToCartOrder(product.product.ProductID, 1);
    };

    const handleDelProductToCart = async () => {
        await delProductToCartOrder(product.product.ProductID);
        setListItem(listItem.filter(el => el.product.ProductID !== product.product.ProductID));
    };

    const handleCloseNotification = () => {
        setShowNotification(false);
    };

    useEffect(() => {
        const updatedProduct = rootStore.cartOrderStore.cartsList.data.find(
            item => item.ProductID === product.product.ProductID
        );
        if (updatedProduct) {
            setListItem(prevList =>
                prevList.map(item =>
                    item.product.ProductID === updatedProduct.ProductID ? { ...item, quantity: updatedProduct.quantity } : item
                )
            );
        }
    }, [rootStore.cartOrderStore.cartsList]);

    return (
        <div>
            <Modal
                open={showNotification}
                onClose={handleCloseNotification}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div style={{ width: "25px" ,height: "24px" }}>
                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                                <path
                                    d="M12 16.99V17M12 7V14M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                                    stroke="#721c24" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                            </g>
                        </svg>
                    </div>

                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {name}
                    </Typography>
                    <Typography sx={{color: "#721c24"}} id="modal-modal-title" variant="h6" component="h2">
                        {t("Limit reached!")}
                    </Typography>
                    <Button onClick={handleCloseNotification} sx={{mt: 2}}>
                        {t("Close")}
                    </Button>
                </Box>
            </Modal>
            <div data-test-id="CartItem" data-value="65f446dbfe4b1adaf8302374" className={styles.mainContainer}>
                <div className={styles.downContent}>
                    <div className={styles.downContentBlock} width="normal" tabIndex="-1">
                        <label htmlFor=":rk:" className={styles.downContentLabel}>Count 1</label>
                        <input
                            id=":rk:"
                            type="number"
                            min="0"
                            step="1"
                            data-test-id="CartItemStepper"
                            aria-hidden="true"
                            className={styles.downContentInput}
                            value={product.quantity}
                            readOnly
                        />
                        <div width="normal" className={styles.downContentDownBlock}>
                            <div className={styles.boxShadow}>
                                {isOpen && isAccess ? (
                                    <div className={styles.panelBtn} style={themeMode === "dark" ? { boxShadow: "none" } : {}}>
                                        <button className={styles.panelBtnItem} onClick={handleMinProductToCart}>
                                            <svg viewBox="0 0 24 24" className={styles.svgBtn}>
                                                <path d="M1.5 13.5h21a1.5 1.5 0 000-3h-21a1.5 1.5 0 000 3z"></path>
                                            </svg>
                                        </button>
                                        <button className={styles.panelBtnItem} onClick={() => setOpen(!isOpen)}>
                                            <span className={styles.textBtnContent}>{product.quantity}</span>
                                        </button>
                                        <button className={styles.panelBtnItem} onClick={handleAddProductToCart}>
                                            <svg viewBox="0 0 24 24" className={styles.svgBtn}>
                                                <path d="M0 12a1.5 1.5 0 001.5 1.5h8.75a.25.25 0 01.25.25v8.75a1.5 1.5 0 003 0v-8.75a.25.25 0 01.25-.25h8.75a1.5 1.5 0 000-3h-8.75a.25.25 0 01-.25-.25V1.5a1.5 1.5 0 00-3 0v8.75a.25.25 0 01-.25.25H1.5A1.5 1.5 0 000 12z"></path>
                                            </svg>
                                        </button>
                                        <button className={styles.panelBtnItem} onClick={handleDelProductToCart}>
                                            <svg viewBox="0 0 24 24" className={styles.deleteBtnImg}>
                                                <path d="M22 4h-4.75a.25.25 0 01-.25-.25V2.5A2.5 2.5 0 0014.5 0h-5A2.5 2.5 0 007 2.5v1.25a.25.25 0 01-.25.25H2a1 1 0 000 2h20a1 1 0 100-2zM9 3.75V2.5a.5.5 0 01.5-.5h5a.5.5 0 01.5.5v1.25a.25.25 0 01-.25.25h-5.5A.25.25 0 019 3.75zM4.5 7.5h15a.5.5 0 01.5.5v14a2 2 0 01-2 2H6a2 2 0 01-2-2V8a.5.5 0 01.5-.5zm5 13.75a.75.75 0 00.75-.75v-9a.75.75 0 00-1.5 0v9c0 .414.336.75.75.75zm5.75-.75a.75.75 0 01-1.5 0v-9a.75.75 0 011.5 0v9z"></path>
                                            </svg>
                                        </button>
                                    </div>
                                ) : (
                                    <button
                                        type="button"
                                        tabIndex="-1"
                                        width="normal"
                                        aria-hidden="true"
                                        className={styles.secondBtn}
                                        onClick={() => setOpen(!isOpen)}
                                    >
                                        <div width="normal" className={styles.secondBtnBlock}>
                                            <div width="normal" className={styles.inSecondBtnBlock}>
                                                {product.quantity}
                                            </div>
                                        </div>
                                        <div width="normal" className={styles.secondUpBtnBlock}>
                                            <div>
                                                <span
                                                    data-test-id="CartItemStepperValue"
                                                    aria-hidden="true"
                                                    className={styles.secondDownBtnText}
                                                >
                                                    {product.quantity}
                                                </span>
                                            </div>
                                        </div>
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <button className={styles.mainBtn}>
                    <div className={styles.mainBtnBlock}>
                        <div className={styles.mainBtnCont}>
                            <img
                                loading="lazy"
                                decoding="async"
                                src={`${process.env.REACT_APP_MEDIA_URL}${product.product.product_images.length ? product?.product?.product_images[0]?.ImageUrl : ""}`}
                                alt=""
                                draggable="true"
                                className={styles.mainImg}
                            />
                            <noscript></noscript>
                        </div>
                    </div>
                    <div data-test-id="cartItemInfo-65f446dbfe4b1adaf8302374-0" className={styles.downContainer}>
                        <span data-test-id="CartItemName" className={styles.downContainerText}>
                            {product.product.ProductName}
                        </span>
                        <div className={styles.downDownContainer}>
                            <span className={styles.downDownContainerText}>
                                {symbol} {product.total_price_converted}
                            </span>
                        </div>
                    </div>
                </button>
            </div>
        </div>
    );
});

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "70vw",
    height: "40vw",
    border: '1px solid #000',
    borderRadius: '1rem',
    p: 4,
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#fff",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    alignItems: "center",
    flexDirection: "column",
    padding: 2,
};

export default OrderItem;
