import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import styled from "./RoundWidget.module.scss";
import { observer } from "mobx-react";
import rootStore from "../../store/RootStore";
import { Checked } from './Checked.jsx';
import { getLastOrderStatus } from "../../api/getContentsApi/getLastOrderStatus";

const RoundWidget = () => {
    const { getIsOpenWidget, setIsOpenWidget } = rootStore.systemStore;
    const { getIsBasketOpen: basketOpen, setBasketOpen, setOrderPanelTab } = rootStore.cartOrderStore;
    const [status, setStatus] = useState({ status: 1 });
    const [draggingTime, setDraggingTime] = useState(0);
    const [dragStarted, setDragStarted] = useState(false);

    const handleCloseVidjet = () => {
        setIsOpenWidget(false);
    };

    const handleOpenOrder = (e) => {
        setOrderPanelTab(1);
        setBasketOpen(true);
    };

    const handleDragStart = (e) => {
        setDragStarted(true);
        setDraggingTime(0);
    };

    const handleDragStop = (e) => {
        setDragStarted(false);
        const timeSpentDragging = new Date().getTime() - draggingTime;
        if (timeSpentDragging < 300) {
            handleOpenOrder(e);
        }
    };

    useEffect(() => {
        let intervalId;

        const fetchStatus = async () => {
            try {
                const data = await getLastOrderStatus();
                setStatus(data);
            } catch (error) {
                console.error("An error occurred while fetching users:", error);
            }
        };

        if (getIsOpenWidget) {
            fetchStatus(); // Первоначальный запрос
            intervalId = setInterval(fetchStatus, 120000);
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [getIsOpenWidget]);

    return (
        <div className={styled.container}>
            {getIsOpenWidget && (
                <Draggable
                    cancel={`.close-button`}  // Отменяем перетаскивание при клике на кнопку закрытия
                    onStart={(e) => {
                        handleDragStart(e);
                        setDraggingTime(new Date().getTime());  // Начинаем отсчет времени
                    }}
                    onStop={handleDragStop}
                >
                    <div
                        className={`${styled.widget} ${status?.status === 2 ? styled.widgetHalfGreen : ''} ${status?.status === 3 ? styled.widgetGreen : ''}`}
                        onClick={handleOpenOrder}  // Обрабатываем клик по самому виджету
                    >
                        {status.status === 1 && <p>Orders</p>}
                        {status.status !== 1 && <Checked />}
                        <button
                            className={`${styled.closeButton} close-button`}
                            onClick={(e) => {
                                e.stopPropagation();  // Останавливаем всплытие при клике на кнопку закрытия
                                handleCloseVidjet();
                            }}
                        >
                            ×
                        </button>
                    </div>
                </Draggable>
            )}
        </div>
    );
};

export default observer(RoundWidget);
