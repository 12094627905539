import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react";
import rootStore from "../store/RootStore";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
import api from "../api/api";
import WithCurierUser from "./WithCourierUser";

const WithCourierUser = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    useEffect(() => {

        const fetchData = async () => {
            try {
                await api.get('user/profile/').then(response => {
                    const res = response.data[0]
                    console.log(res?.role)
                    if (res?.role !== "courier") {
                        navigate('/discovery/me');
                        alert("You are not a courier");
                    }
                });
                await rootStore.superUserStore.lazyInitialize();
                setIsLoading(false);
            }catch(err) {
                navigate('/discovery/me');
                alert("You are not a courier");
            }
        };
        fetchData();
    }, []);

    return (
        isLoading ? (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
                <CircularProgress />
            </Box>
        ) : (
            <>
                {children}
            </>
        )
    );
};

export default observer(WithCourierUser);