import api from "../api";

export const getBundlesInfo = async (shopId,id) => {
    try {
        const users = await api.get(`/admin_seller_shop/my-shop/${shopId}/bundles/${id}/`);
        return users.data;
    } catch (error) {
        console.error("An error occurred:", error);
        throw error;
    }
}