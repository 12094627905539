import api from "../api";

export const addBundle = async (formData,id= null,shopId) => {
    if (formData && id && shopId) {
        try {

            const apiResponse = await api.patch(
                `/admin_seller_shop/my-shop/${shopId}/bundles/${id}/update/`,
                formData,

            );
            return apiResponse;
        } catch (error) {
            console.error("An error occurred:", error);
            throw error;
        }
    }
    else {
        try {
            const apiResponse = await api.post(
                `/admin_seller_shop/my-shop/${shopId}/bundles/create-with-items/`,
                formData,

            );
            return apiResponse;
        } catch (error) {
            console.error("An error occurred:", error);
            throw error;
        }
    }
};
