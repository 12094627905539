import React, { useEffect, useState, useMemo } from 'react';

import { toJS } from "mobx";
import { observer } from "mobx-react";

import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import { CircularProgress } from "@mui/material";

import styles from './OrdersConfirmPanel.module.scss'

import rootStore from "../../store/RootStore";
import HistoryCart from "../HistoryCart/HistoryCart";
import OrderInfoModal from "./OrderInfoModal/OrderInfoModal";
import { ORDER_STATUSES } from "../../constants/orderStatuses";
import { sortOrdersByStatus } from "./helpers";
import {useNavigate} from "react-router-dom";
import {setActivNavModer} from "../../helpers";

const OrdersConfirmPanel = ({names}) => {
    const shops = rootStore.superUserStore.superUserShopsList;
    const { setCurrentShopId } = rootStore.superUserStore;
    const setCurrentShopData = rootStore.superUserStore.setCurrentData;
    const { ordersList, loadOrders,isLoadingOrders } = rootStore.ordersModerStore;

    const [openInfo, setOpenInfo] = useState(false);
    const [curierName, setCurierName] = useState();
    const [value, setValue] = React.useState(() => {
        const storedValue = localStorage.getItem('selectedTab');
        return storedValue ? parseInt(storedValue) : 0;
    });

    const handleChange = (event, newValue) => {
        setValue(newValue);
        localStorage.setItem('selectedTab', newValue.toString());
        localStorage.setItem('currentShop', JSON.stringify(shops[newValue]));
        setCurrentShopId(shops[newValue]?.ShopID);
        setCurrentShopData(shops[newValue]);
        loadOrders(shops[newValue]?.ShopID)
    };
    const navigate = useNavigate();

    useEffect(() => {
        if(!localStorage.getItem('currentShop')){
            navigate(`/moderator/setting`);
            alert("select Shop")
            setActivNavModer("Setting");
        }
        if(localStorage.getItem('currentShop')){
            loadOrders(JSON.parse(localStorage.getItem("currentShop"))?.ShopID)
        }
    }, []);


    function a11yProps(index) {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        };
    }
    const handleOpenInfo = (name) => {
        setCurierName(name)
        setOpenInfo(true)
    }


    const sortedOrders = useMemo(() => {
        if(!ordersList.length){
            return [];
        }
        else {
            return sortOrdersByStatus(toJS(ordersList), ORDER_STATUSES);
        }

    }, [ordersList]);

    return (
        <div className={styles.mainConteiner}>
            <Box
                sx={{flexGrow: 1, bgcolor: 'background.paper', display: 'flex',flexDirection: true ? "column" : "row", height: "100%"}}
            >
                {!!shops.length && <Tabs
                    orientation={true ? "horizontal" : "vertical"}
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    sx={{borderRight: 1, borderColor: 'divider', minWidth: true ? 256 : 300}}
                >
                    {shops.map((shop, index) => (
                        <Tab label={shop.ShopName} {...a11yProps(shop.ShopID)} key={shop.ShopID}/>
                    ))}
                </Tabs>}
                {isLoadingOrders && <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "50vh"}}>
                    <CircularProgress/>
                </Box>}
                {!!sortedOrders?.length ? sortedOrders.map((order, index) => (
                    <div className={styles.curierBig} onClick={() => handleOpenInfo(order.username)}>
                        <HistoryCart data={order} index={index}/>
                    </div>
                )):<div>No orders</div>}
            </Box>
            <div className={styles.contentBlock}>

            </div>
            {openInfo && <OrderInfoModal setState={setOpenInfo} username={curierName}/>}
        </div>
    );
};

export default observer(OrdersConfirmPanel);
