import { makeAutoObservable } from 'mobx';

class SystemStore {
    isOpenLoginModal = false;
    isLogin = false;
    isRegister = false;
    sizeWidth = null;
    isOpenPersonal = false;
    isOpenEditPhoneModal = false;
    languages = [];
    navBarHidden = false;
    isOpenOrderWidget = JSON.parse(localStorage.getItem('isOpenOrderWidget')) || false;
    openMediaFull = false;

    constructor() {
        makeAutoObservable(this);
    }

    get openMediaState() {
        return this.openMediaFull;
    }

    setOpenMediaState = () => {
        this.openMediaFull = !this.openMediaFull;
    }

    setNavBarHidden = (state) => {
        this.navBarHidden = state;
    }

    get getIsOpenPersonal() {
        return this.isOpenPersonal;
    }

    get getIsOpenPersonalModal() {
        return this.isOpenLoginModal;
    }

    get getIsOpenWidget() {
        return this.isOpenOrderWidget;
    }

    setIsOpenWidget = (state) => {
        this.isOpenOrderWidget = state;
        localStorage.setItem('isOpenOrderWidget', JSON.stringify(state));
    }

    setWidthSize = (width) => {
        this.sizeWidth = width;
    }

    setIsOpenPersonal = (state) => {
        this.isOpenPersonal = state;
    }

    openCloseL = (isLogin) => {
        this.isOpenLoginModal = !this.isOpenLoginModal;
        this.isLogin = isLogin;
    }

    openCloseR = (isLogin) => {
        this.isOpenLoginModal = !this.isOpenLoginModal;
        this.isRegister = isLogin;
    }

    setLogin = () => {
        this.isLogin = false;
        this.isRegister = true;
    }

    setRegister = () => {
        this.isRegister = false;
        this.isLogin = true;
    }

    setIsOpenLoginModal = () => {
        this.isOpenLoginModal = false;
    }

    openCloseEP = () => {
        this.isOpenEditPhoneModal = !this.isOpenEditPhoneModal;
    }
}

export default new SystemStore();
