import * as React from "react";

export const Checked = () => (
    <div style={{ width:24,height:24, zIndex: 4 }}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="#fff"
            stroke="#fff"
            ariaHidden="true"
            className="iconify iconify--twemoji"
            viewBox="0 0 36 36"
        >
            <path
                id="SVGRepo_iconCarrier"
                d="M34.459 1.375a3 3 0 0 0-4.149.884L13.5 28.17l-8.198-7.58a2.999 2.999 0 1 0-4.073 4.405l10.764 9.952s.309.266.452.359a3 3 0 0 0 4.15-.884L35.343 5.524a3 3 0 0 0-.884-4.149z"
            ></path>
        </svg>
    </div>

);

