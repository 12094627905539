import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import styles from "./Basket.module.scss";
import CloseImage from "../Footer/static/CloseImage";
import BasketPanel from "../BasketPanel/BasketPanel";
import IconDown from "../Icons/IconDown";
import rootStore from "../../store/RootStore";
import OrderAgine from "./OrderAgine/OrderAgine";
import {observer} from "mobx-react";
import {t} from "i18next";
import {Switch} from "@mui/material";

const Basket = ({ closeDrawer, nextStep = false }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const {setOpenOrderAgine, getOrderAgine, cartsList} = rootStore.cartOrderStore;
    const { setIsOpenWidget,getIsOpenWidget } = rootStore.systemStore;
    const listItem = cartsList.data;
    const [isOpevView, setIsOpevView] = useState(nextStep);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleClose = () => {
        if (isOpevView && !window.location.pathname.includes('/stores/')) {
            setIsOpevView(false)
        } else {
            closeDrawer();
        }
    }
    useEffect(()=>{
        if(cartsList?.data?.length === 0){

            setIsOpevView(false)
        }
    },[cartsList?.data?.length])


    return (
        <>
            <Box
                className={styles.basket}
                sx={{
                    width: windowWidth > 830 ? '550px' : '100%',
                    height: windowWidth > 830 ? '100%' : '100vh',
                    zIndex: 99999999
                }}
                role="presentation"
            >
                {
                    windowWidth < 640 ?
                        (
                            <>
                                <div className={styles.headerContent}>
                                    <IconDown stateClose={() => handleClose()}/>
                                    <p  className={styles.title}>{isOpevView && listItem.length ? cartsList['get_shop'].ShopName : t("Your orders")}</p>

                                    <div className={styles.vidjetOrderBlock}>
                                        <p className={styles.orderTitle}>{t("Order view:")}</p>
                                        <Switch
                                            sx={{right: '-25px'}}
                                            checked={getIsOpenWidget}
                                            onChange={() => setIsOpenWidget(!getIsOpenWidget)}/>
                                    </div>
                                </div>
                                <BasketPanel stateClose={handleClose} isOpevView={isOpevView} setIsOpevView={setIsOpevView}/>
                            </>
                        ) :
                        (
                            <>
                                <div className={styles.header}>
                                    <div className={styles.vidjetOrderBlock}>
                                        <p className={styles.orderTitle}>{t("Order view:")}</p>
                                        <Switch
                                            sx={{right: '-25px'}}
                                            checked={getIsOpenWidget}
                                            onChange={() => setIsOpenWidget(!getIsOpenWidget)}/>
                                    </div>
                                    <div onClick={() => handleClose()}>
                                        <CloseImage size={44}></CloseImage>
                                    </div>
                                </div>
                                <p className={styles.title}>{t("Your orders")}</p>
                                <BasketPanel stateClose={closeDrawer} isOpevView={isOpevView}
                                             setIsOpevView={setIsOpevView}/>
                            </>
                        )
                }
            </Box>
            {getOrderAgine && (<OrderAgine closeDrawer={setOpenOrderAgine}/>)}
        </>
    );
};

export default observer(Basket);
