import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
import ProductSearch from "./ProductSearch";
import CreateProductButton from "./CreateProductButton";
import ProductTable from "./ProductTable";
import ActionButtons from "./ActionButtons";
import styles from "./ModeratorBodyMenuTable.module.scss";
import {useDebounce} from "../../../../helpers/useDebounce";
import {deleteProduct} from "../../../../api/addContent/deleteProduct";
import rootStore from "../../../../store/RootStore";
import {getShopProducts} from "../../../../api/getContentsApi/getShopProduct";
import ModeratorInfoModal from "./ModeratorInfoModal/ModeratorInfoModal";
import {setActivNavModer} from "../../../../helpers";
import {useNavigate} from "react-router-dom";

const ModeratorBodyMenuTable = observer(() => {
    const { setIfOpenCreateProtuct,setIsOpenEditProduct,setCurrentEditProductId } = rootStore.moderatorPanelStore;
    const [input, setInput] = useState("");
    const storedShopValue = localStorage.getItem("currentShop")||null;

    const navigate = useNavigate()

    useEffect(()=>{
        if(storedShopValue === "undefined"){
            alert("Add shops")
            setActivNavModer("setting");
           navigate("/moderator/setting");
        }

    },[storedShopValue]);

    if(storedShopValue === "undefined"){
        return null;
    }

    let shopId = rootStore.superUserStore.currentShopId || JSON.parse(storedShopValue)?.ShopID;
    const currentCategoryID = rootStore.categoriesStore.currentCategoryID;
    const { getLoader, setLoader } = rootStore.shopStore;
    const [currentProducts, setCurrentProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [selectedProductsId, setSelectedProductsId] = useState([]);
    const [showButtons, setShowButtons] = useState(false);
    const debouncedSearchTerm = useDebounce(input, 100);
    const [notFound, setNotFound] = useState(false);
    const [state, setState] = useState(false);

    useEffect(() => {
        return () => {
            rootStore.shopStore.clearShopData();
            rootStore.categoriesStore.setCurrentCategoryID(0);
        };
    }, []);

    useEffect(() => {
        getShopProducts(shopId)
            .then(response => {
                setLoader(false);
                const responseData = response?.data?.products.map((item) => ({
                    id: item.ProductID,
                    ProductName: item.ProductName,
                    Product_info: item.Product_info,
                    unit: item.unit,
                    Description: item.Description,
                    Price: item.Price,
                    quantity: item.quantity,
                    CategoryID: item.CategoryID,
                }));
                setCurrentProducts(responseData);
            })
            .catch(error => console.log(error));
    }, [shopId, currentCategoryID,!input,]);

    useEffect(() => {
        if (currentCategoryID === 0 && currentProducts) {
            setFilteredProducts(currentProducts);
        } else if (currentProducts) {
            const filtered = currentProducts.filter(el => el.CategoryID === currentCategoryID);
            setFilteredProducts(filtered);
        }
    }, [currentCategoryID, currentProducts]);

    const handleOpenCreateProduct = () => {
        setIfOpenCreateProtuct(true);
    };

    const handleSelectionChange = (ids) => {
        setSelectedProductsId(ids);
        setShowButtons(ids.length > 0);
    };

    const handleEdit = (selectedProductsId) => {
        setCurrentEditProductId(selectedProductsId[0]);
        setIsOpenEditProduct(true);
    };

    useEffect(() => {
        if (debouncedSearchTerm) {
            const filtered = currentProducts.filter(el =>
                Object.values(el).some(value =>
                    typeof value === 'string' && value.toLowerCase().includes(input.toLowerCase())
                )
            );
            setNotFound(filtered.length === 0)
            setFilteredProducts(filtered);
        }
    },[debouncedSearchTerm,input])

    const handleDelete = () => {
        selectedProductsId.forEach((id) => {
            deleteProduct(id).then(() => {
                getShopProducts(shopId)
                    .then(response => {
                        setLoader(false);
                        const responseData = response?.data?.products.map((item) => ({
                            id: item.ProductID,
                            ProductName: item.ProductName,
                            Product_info: item.Product_info,
                            unit: item.unit,
                            Description: item.Description,
                            Price: item.Price,
                            quantity: item.quantity,
                            CategoryID: item.CategoryID,
                        }));
                        setCurrentProducts(responseData);
                    })
                    .catch(error => console.log(error));
            });
        });
    };
    return (
        <div className={styles.contentMain}>
            {getLoader ? (
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <ProductSearch input={input} setInput={setInput} />
                    <CreateProductButton onClick={handleOpenCreateProduct} />
                    <ProductTable
                        notFound={notFound}
                        currentProducts={currentProducts}
                        filteredProducts={filteredProducts}
                        currentCategoryID={currentCategoryID}
                        handleSelectionChange={handleSelectionChange}
                    />
                    {showButtons && (
                        <ActionButtons
                            handleDelete={() => setState(true)}
                            handleEdit={() => handleEdit(selectedProductsId)}
                            selectedProductsId={selectedProductsId}
                        />
                    )}
                </>
            )}
            {state && <ModeratorInfoModal setState={setState} action={handleDelete}/>}
        </div>
    );
});

export default ModeratorBodyMenuTable;
